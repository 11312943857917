import React from 'react';
import ReactDOM from 'react-dom';
// import '../src/css/normalize.css';
// import '../src/css/skeleton.css';
import '../src/css/style.css';

import App from './app.js';

ReactDOM.render (
  <App />,
  document.getElementById('root')
);