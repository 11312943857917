import React, { Component } from 'react';


class MTGCard extends Component {


  render() {
    const { data, searchedTerm, missingImageTerm } = this.props;
    console.log("mtgCard data: ", data);

    return (
      <div className="container">
        {data.length > 0 ? data.map(e => {
          return e.set === "VAN" ?
            <p key={e.id}>{e.name} exists in the vanguard set, but I'm currently ignoring all vanguard cards, sorry</p>
            :
            <div className="card-container" key={e.id}>
              {
                e.imageUrl === missingImageTerm ?
                  <img src={require("../images/missingCard.png")} alt={"Missing photo image: " + e.name} className="mtg-card-image" />
                  :
                  <img src={e.imageUrl} alt={"Image for: " + e.name} className="mtg-card-image" />
              }
              <h6 className="text-left">{e.text}</h6>
              <h6 style={{"padding-bottom": "1rem"}}><em>{e.flavor ? e.flavor : ""}</em></h6>
              <table className="table table-hover text-left">
                
                {/* FIRST PART OF THE TABLE - IT MUST BE SMALL ENOUGH TO VIEW ON MOBILE */}
                <thead className="custom-mtg-table-header-bg-color">
                  <tr>
                    <th>Name</th>
                    <th>Power / Toughness</th>
                    <th>Rarity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{e.name ? e.name : "N/A"} </td>
                    <td>{e.power ? e.power + "/" + e.toughness : "N/A"}</td>
                    <td>{e.rarity ? e.rarity : "N/A"}</td>
                    {/* <td>Color Identity: {e.colorIdentity ? e.colorIdentity.map((color, i) => e.colors.length > (i + 1) ? color + ", " : color) : "Nothing Listed Per Official MTG Server"}</td> */}
                  </tr>
                </tbody>

                {/* SECOND PART OF THE TABLE --- GOTTA KEEP IT SMALL */}
                <thead className="custom-mtg-table-header-bg-color">
                  <tr>
                    <th>Color</th>
                    <th>Mana</th>
                    <th>Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{e.colors ? e.colors.map((color, i) => e.colors.length > (i + 1) ? color + ", " : color) : "N/A"}</td>
                    <td>{e.manaCost ? e.manaCost : "N/A"}</td>
                    <td>{e.cmc ? e.cmc : "N/A"}</td>
                  </tr>
                </tbody>

                {/* THIRD PART OF THE TABLE --- GOTTA KEEP IT SMALL :/ */}
                <thead className="custom-mtg-table-header-bg-color">
                  <tr>
                    <th>Types</th>
                    <th>Subtypes</th>
                    <th>Supertype</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{e.types.length > 0 ? e.types.map((type, i) => e.types.length > (i + 1) ? type + ", " : type) : "N/A"}</td>
                    <td>{e.subtypes ? e.subtypes.length > 0 ? e.subtypes.map((sub, i) => e.subtypes.length > (i + 1) ? sub + ", " : sub) : "N/A" : "N/A"}</td>
                    <td>{e.supertypes ? e.supertypes.length > 0 ? e.supertypes.map((sup, i) => e.supertypes.length > (i + 1) ? sup + ", " : sup) : "N/A" : "N/A"}</td>
                  </tr>
                </tbody>

                {/* FOURTH PART OF THE TABLE -- DAMN THIS IS GETTING OUT OF HAND */}
                <thead className="custom-mtg-table-header-bg-color">
                  <tr>
                    <th>Set</th>
                    <th>Card Number</th>
                    <th>Artist</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{e.setName ? e.setName : "N/A"}</td>
                    <td>{e.number ? e.number : "N/A"}</td>
                    <td>{e.artist ? e.artist : "N/A"}</td>
                  </tr>
                </tbody>
              </table>
              <a href={"https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Dtoys-and-games&field-keywords=Magic The Gathering " + e.name} target="_blank" rel="noopener noreferrer">
                Find {e.name} on amazon
                </a>
            </div>
        })
          :
          <h4>No Results Found For "{searchedTerm}" - Maybe there was a spelling or puncuation error: Try Again</h4>}
      </div>
    );
  }
}

export default MTGCard;




// return (
// <div>
//   {data ? data.map(e => {
//     return e.set === "VAN" ? <p key={e.id}>"Some of These cards exist in the vanguard set, but im ignoring them"</p> :
//       <div className="card-container" key={e.id}>
//         <img src={e.imageUrl} alt={"MTG card" + e.name} />
//         <p>{e.name} {e.number ? "  |  " + e.number : ""} </p>
//         <p>Power/Toughness: {e.power ? e.power + "/" + e.toughness : "N/A"}</p>
//         <p>Mana Cost: {e.manaCost}</p>
//         <p>Total Mana Cost: {e.cmc}</p>
//         <p>Colors: {e.colors ? e.colors.map((color, i) => e.colors.length > (i + 1) ? color + ", " : color) : "Nothing Listed Per Official MTG Server"}</p>
//         <p>Color Identity: {e.colorIdentity ? e.colorIdentity.map((color, i) => e.colors.length > (i + 1) ? color + ", " : color) : "Nothing Listed Per Official MTG Server"}</p>
//         <p>{e.type}</p>
//         <p>Type: {e.types.map((type, i) => e.types.length > (i + 1) ? type + ", " : type)}</p>
//         <p>Subtypes: {e.subtypes ? e.subtypes.map((sub, i) => e.subtypes.length > (i + 1) ? sub + ", " : sub) : "Nothing Listed Per Official MTG Server"}</p>
//         <p>Rarity: {e.rarity}</p>
//         <p>Set: {e.setName}</p>
//         <p>Watermark: {e.watermark}</p>
//         <p>{e.text}</p>
//       </div>
//   }) : ""}
// </div>
// );