import React, { Component } from 'react';
import LinkComponent from './linkComponent';

class HomePage extends Component {


  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {

    return (
      <section className="section" id="home-section" style={{backgroundColor: "black"}}>
        <div className="container">
          <img src={require("../images/homeBannerRetouch.png")} alt="Home Banner" id="homepage-img" />
          {/* <img src={require("../images/homeBannerRetouchLongerThing.png")} alt="Home Banner" id="homepage-img" /> */}
        </div>
        <div className="container">
          <h4 className="main-heading">Welcome</h4>
          <br></br>
          <div className="body-text" style={{color: "white"}}>

            <p style={{color: 'brown'}}>
              Welcome to my&nbsp;
              <em style={{color: 'orange'}} title="was this on purpose?">[name of website]</em>. 
              I hope you're enjoying your stay at&nbsp;
              <em style={{color: 'orange'}} title="did I forget a default?">[name of website]</em>. 
            </p>

            <br></br>
            <br></br>
            <br></br>
            <br></br>


            <LinkComponent
              textColor="hotpink"
              address="/images"
              text="The Image Manipulation page allows you to input an image link into a field and then perform four image processing algorithms on the linked image.  It's my personal favorite page."
            />

            <br></br>
            <LinkComponent
              textColor="orange"
              address="/mtg"
              text="The MTG page uses an API for Magic The Gathering to display card names, images, and data based on a users input."
            />

            <br></br>
            <LinkComponent
              textColor="yellow"
              address="/music"
              text="The music page has a list of songs from YouTube that I've hand selected.  Please take a moment to check it out, I hope you find something you like."
            />

            <br></br>
            <LinkComponent
              textColor="red"
              address="/portfolio"
              text="My projects can be found on the portfolio page."
            />

          <br></br>
          <br></br>
          <br></br>
          {/* <img src="http://i.giphy.com/GvDyDhp0EQnBe.gif" alt="can't escape reality" /> */}
          {/* <img src={require("../images/reality.gif")} alt="dreams vs reality"  */}
          <img src={require("../images/monkey.gif")} alt="dreams vs reality" 
               style={{display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%"}} />


            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {/* <div style={{color: "lightblue"}}>
              My contact information can be found at the bottom of any page.
            </div> */}
            <br></br>
            <br></br>
          </div>

          {/* <p style={{color: "white"}}>"
          <em>
            If you wish to make an apple pie from scratch, you must first invent the universe.
          </em>"
          <br></br>
            -Carl Sagan
          </p> */}
        </div>
      </section>
    );
  }

}

export default HomePage;
