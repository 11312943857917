import React from 'react'
import { NavLink } from "react-router-dom";


function LinkComponent(props) {
  return (
    <NavLink id="removeLinkUnderline" to={props.address}>
      <div id="linkComponentStyle" className="standard-link" style={{color: props.textColor}}>
        {props.text}
      </div>
    </NavLink>
  );
}

export default LinkComponent;
