import React, { Component } from 'react';
import MTGCard from './mtgCard';


class MTGAPI extends Component {

  constructor() {
    super();

    this.state = {
      data: {},
      searchTerm: '',
      searchedTerm: '',
      key: 0,
      currentPageCount: 0,
      totalCount: 0,
      pageSize: 0,
      userResultsPerPage: 25,
      page: 0,
      loading: false,
      pageStart: true,
      missingImageUrl: "missing",
      checkBoxSelected: false,
    }
  }


  componentDidMount() {
    this.moveToTop();
  }

  moveToTop() {
    window.scrollTo(0, 0);
  }

  pageLoad() {
    this.setState({
      loading: true,
      pageStart: false,
    })
  }

  submitted(event) {
    event.preventDefault();

    const { page } = this.state;

    //offset has to be used to set the data back to page one
    //so the result has to end at page - offset = 1
    let offset;
    if (page > 1) {
      offset = (-1 * page) + 1;
    } else if (page === 0) {
      offset = 1;
    } else {
      offset = 0;
    }
    this.pageLoad();
    this.newCard(offset);
  }

  searchTermChanged(event) {
    this.setState({
      searchTerm: event.target.value,
    })
  }

  checkForEnterKeyPress(event) {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  }

  setSelectedPerPageResults(event) {
    this.setState({
      userResultsPerPage: Number(event.target.value)
    })
  }

  loadPrevPage(event) {
    event.preventDefault();
    const offset = -1;
    this.pageLoad();
    this.newCard(offset);
    this.moveToTop();
  }

  loadNextPage(event) {
    event.preventDefault();
    const offset = 1;
    this.pageLoad();
    this.newCard(offset);
    this.moveToTop();
  }

  getTotalPages() {
    const { totalCount, pageSize, data } = this.state;
    if (data.cards) {
      return Math.ceil(totalCount / pageSize);
    } else {
      return 0;
    }
  }

  newCard(offset) {
    const { searchTerm, userResultsPerPage, page, key } = this.state;
    let url = "https://api.magicthegathering.io/v1/cards?name="
      + searchTerm
      + "&page=" + (page + offset)
      + "&pageSize=" + userResultsPerPage;
    fetch(url)
      .then((res) => {
        let currentPageCount = res.headers.get('count');
        let totalCount = res.headers.get('total-count');
        let pageSize = res.headers.get('page-size');
        this.setState({
          currentPageCount,
          totalCount,
          pageSize,
          page: (page + offset),
        })
        return res.json();
      })
      .then(data => {
        this.setState({
          data,
          key: key + 1,
          searchedTerm: searchTerm,
          loading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  checkBoxChange() {
    this.setState({
      checkBoxSelected: !this.state.checkBoxSelected,
    })
  }



  render() {
    const {
      data,
      searchedTerm,
      key,
      currentPageCount,
      totalCount,
      userResultsPerPage,
      page,
      loading,
      pageStart,
      missingImageUrl,
      checkBoxSelected,
    } = this.state;

    let totalPages = this.getTotalPages();

    //FILTER OUT CARDS WITH MISSING IMAGES
    let newData;
    //if the checkbox is selected then show missing images, otherwise dont
    if (data.cards && checkBoxSelected) {
      newData = data.cards.filter(img => {
        if (img.imageUrl) {
          return img;
        }
        img.imageUrl = missingImageUrl;
        return img;
      })
    } else if (data.cards) {
      newData = data.cards.filter(img => img.imageUrl && img.imageUrl !== missingImageUrl)
    }


    return (
      <section className="section" id="mtg-section" >
        <h3 className="main-heading">Search For Magic Cards</h3>

        <div className="container">
          <form onSubmit={(event) => this.submitted(event)}>
            {/* <label className="main-heading"><h3>Search For Magic Cards</h3></label> */}


            {/* SEARCH BY SET: hopefully, still need a lot of data as to what the sets are */}
            {/* Its also using old css from skeleton, which I had to get rid of, due to it being too barebones */}
            {/* <div className="row">
              <div className="six columns">
                <select value={"search by set"}>
                  <option value="all" disabled>Search By Set</option>
                </select>
              </div>
            </div> */}

            <div className="form-row">
              <div className="form-group col-md-7">
                <input
                  onChange={(event) => this.searchTermChanged(event)}
                  onKeyPress={(event) => this.checkForEnterKeyPress(event)}
                  placeholder="Search for cards... ex: vampire"
                  className="form-control"
                  type="text"
                />
              </div>
              <div id="mtg-search-button-div" className="form-group col-md-2">
                <button className="btn mtg-page-buttons" type="submit">Search</button>
              </div>
              {/* page selector dropdown menu */}
              <div className="form-group justify-content-center">
                <select className="form-control" value={userResultsPerPage} onChange={event => this.setSelectedPerPageResults(event)}>
                  <option disabled>Items Per Page</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>

            {/* DISPLAY MISSING IMAGES checkbox */}
            <div className="form-check">
              <label className="container">
                <input type="checkbox" defaultChecked="" onChange={() => this.checkBoxChange()} />
                <span style={{"paddingLeft": "0.3rem"}}>Show Data For Cards With Missing Images</span>
              </label>
            </div>

            <div id="mtg-mobile-button" className="row">
              <button className="btn mtg-page-buttons" type="submit">Search</button>
            </div>
          </form>

          <div className="container">
            {data.cards ?
              <div className="container text-center" style={{"PaddingTop": "1rem", "paddingBottom": "1rem"}}>
                  <h6><strong>Found {totalCount} total results for "{searchedTerm}"</strong></h6>
                  {loading ? '' :
                    <div>
                      <h6><strong>Page {totalPages === 0 ? 0 : page} of {totalPages}</strong></h6>
                      {newData.length !== Number(currentPageCount) ?
                        <h6 style={{"paddingBottom": "2rem"}}>Displaying {newData.length} out of {currentPageCount} due to removing entires with missing images</h6> : ''}
                    </div>
                  }

                  {/* <h6>
                       displaying &nbsp;
                       {page === totalPages ? totalCount - newData.length : newData.length * page}&nbsp;
                       out of &nbsp;
                       {totalCount} results
                       </h6>
                      } */}
                {loading ? '' : <MTGCard data={newData} searchedTerm={searchedTerm} key={key} missingImageTerm={missingImageUrl} />}
              </div>
              : ''}
          </div>

          <div>
            {/* If data.cards has value so does newData so the checks are the same, but i could just check newData here */}
            {/* newData is the object set up for plugging in "missing images photo" */}
            {/* {loading ?
              <div className="row">
                <div className="nine columns">
                  Loading...
                </div>
              </div>
              :
              data.cards ? <MTGCard data={newData} searchedTerm={searchedTerm} key={key} missingImageTerm={missingImageUrl} /> : ''} */}

          </div>
          <div className="container">
              {page <= 1 ? '' : <button className="btn mtg-page-buttons" onClick={(event) => this.loadPrevPage(event)}>Prev</button>}
              {page < totalPages ? <button className="btn mtg-page-buttons" onClick={(event) => this.loadNextPage(event)}>Next</button> : ''}
          </div>
        </div>

        {pageStart ?
          <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          : loading ? <h5>Loading... </h5> : ''}
      </section>
    );
  }
}

export default MTGAPI;