import React from 'react';

function Videos(props) {
  if (props.videos.length > 0) {
    return props.videos.map((video) => {
      return (
        <div className="video-title">
          {video.title}
          <div>
            <iframe
              className="videos"
              width="560"
              height="455"
              frameBorder="0"
              title={video.title}
              key={video.videoId}
              src={"https://www.youtube.com/embed/" + video.videoId}
              allowFullScreen="allowfullscreen">
            </iframe>
          </div>
          <br></br>
        </div>
      );
    })
  }

  return <h1>No Results Found</h1>;
}

export default Videos;