import React, { Component } from 'react';


//I really should create a function to show or hide the divs instead of peppering them throghout the file

class ImageManipulation extends Component {

  constructor() {
    super();

    this.state = {
      error: false,
    }
    this.handleError = this.handleError.bind(this);
    this.handleImageLoad = this.handleImageLoad.bind(this);

    this.imageSrc = "https://images.unsplash.com/photo-1578326626553-39f72c545b07?ixlib=rb-1.2.1&auto=format&fit=crop&w=729&q=80";

  }

  componentDidMount() {
    this.moveToTop();
    // this.onSubmitting();
  }

  moveToTop() {
    window.scrollTo(0, 0);
  }

  onSubmitting() {
    //I have to do this for the 'loading' to be rendered.  I have to have this timeout
    document.getElementById("loadingDiv").style.display = "block";
    // document.getElementById("linkError").style.display = "none";
    setTimeout(() => { this.executeAllDrawing() }, 100);
  }

  handleError() {
    document.getElementById("loadingDiv").style.display = "none";
    // document.getElementById("linkError").style.display = "block";
    // console.log("Error With Linked Image")
    this.setState({
      error: true,
    });
    setTimeout(() => { }, 100);
  }

  handleImageLoad() {
    this.setState({
      error: false,
    })
  }

  executeAllDrawing() {


    document.getElementById("the-image-to-manipulate").style.display = "inline";
    setTimeout(() => { }, 100);
    document.getElementById("the-image-to-manipulate").onerror = this.handleError;
    document.getElementById("the-image-to-manipulate").onload = this.handleImageLoad;
    let img = document.getElementById("the-image-to-manipulate");


    // img.onerror = () => {
    if (img.height <= 0 || img.width <= 0) {
      document.getElementById("loadingDiv").style.display = "none";
      // document.getElementById("linkError").style.display = "block";
      //notify the user that this is not a valid image
      return;
      // }
    }

    if (this.state.error) {
      document.getElementById("loadingDiv").style.display = "none";
      // document.getElementById("linkError").style.display = "block";
      return;
    }
    // console.log("img: ", img);
    // console.log("img height:", img.height);
    // console.log("doc height:", document.getElementById("the-image-to-manipulate").style.height);


    let canvas = document.getElementById("myCanvas");
    let differentCanvas = document.getElementById("myOtherCanvas")
    let anotherCanvas = document.getElementById("myOtherOtherCanvas")
    let againAnotherCanvas = document.getElementById("myOtherOtherOtherCanvas")
    // console.log("Canvas H:", canvas.height, "Canvas W:", canvas.width);

    let ctx = canvas.getContext("2d");
    let differentCTX = differentCanvas.getContext("2d");
    let anotherCTX = anotherCanvas.getContext("2d");
    let againAnotherCTX = againAnotherCanvas.getContext("2d");

    let particles = [];

    // document.getElementById("loadingDiv").style.display = "inline";
    // img.onload = () => {
    this.draw(canvas, img, ctx, particles);
    this.differentDraw(differentCanvas, img, differentCTX, particles);
    this.anotherDraw(anotherCanvas, img, anotherCTX, particles);
    this.againAnotherDraw(againAnotherCanvas, img, againAnotherCTX, particles);
    // document.getElementById("the-image-to-manipulate").style.display = "none";
    img.style.display = "none";
    // img.style.visibility = "hidden";
    // img.src = "";
    // }

    document.getElementById("loadingDiv").style.display = "none";

  }


  draw(canvas, img, ctx, particles) {
    canvas.height = img.height;
    canvas.width = img.width;
    // console.log("img height:", img.height, "img width", img.width);

    ctx.drawImage(img, 0, 0, img.width, img.height);
    let data = ctx.getImageData(0, 0, img.width, img.height);
    // console.log("data length:", data.data.length, "data height:", data.height, "data width", data.width);
    // ctx.clearRect(0, 0, canvas.width, canvas.height);
    let particle = {};
    particles.length = 0;  // LOOKS LIKE A STATE UPDATE TO ME

    //seems like a state update should be here, but this is a lot of calls to state. so update state after this is finished
    for (let y = 0; y < data.height; y++) {
      for (let x = 0; x < data.width; x++) {
        let p = (x + y * data.width) * 4;
        particle = {
          x: x,
          y: y,
          red: data.data[p],
          green: data.data[p + 1],
          blue: data.data[p + 2],
          alpha: data.data[p + 3],
        }
        particles.push(particle);
      }
    }

    // ctx.clearRect(0, 0, canvas.width, canvas.height);
    // console.log(particles.length);
    for (let i = 0; i < particles.length; i++) {
      // console.log("r:", particles[i].red, "g:", particles[i].green, "b:", particles[i].blue);
      ctx.fillStyle = `rgba(
        ${particles[i].red}, 
        ${particles[i].green},
        ${particles[i].blue},
        ${particles[i].alpha})`;
      ctx.fillRect(particles[i].x, particles[i].y, 2, 2);
    }
  }


  differentDraw(differentCanvas, img, differentCTX, particles) {
    differentCanvas.height = img.height;
    differentCanvas.width = img.width;
    differentCTX.drawImage(img, 0, 0, img.width, img.height);
    let data = differentCTX.getImageData(0, 0, img.width, img.height);
    // differentCTX.clearRect(0, 0, canvas.width, canvas.height);
    let particle = {};
    particles.length = 0;

    for (let y = 0; y < data.height; y++) {
      for (let x = 0; x < data.width; x++) {
        let p = (x + y * data.width) * 4;
        if (data.data[p] > 20 && data.data[p] < 128) {
          particle = {
            x: x,
            y: y,
            color: "#080838",
          }
          particles.push(particle);
        } else if (data.data[p] < 170) {
          particle = {
            x: x,
            y: y,
            color: "#898989",
          }
          particles.push(particle);
        } else if (data.data[p] < 160) {
          particle = {
            x: x,
            y: y,
            color: "#d0430b",
          }
          particles.push(particle);
        } else if (data.data[p] >= 160) {
          particle = {
            x: x,
            y: y,
            color: "#F5CCB0",
          }
          particles.push(particle);
        }
      }
    }
    // differentCTX.clearRect(0, 0, canvas.width, canvas.height);
    // console.log(particles.length);
    for (let i = 0; i < particles.length; i++) {
      differentCTX.fillStyle = particles[i].color;
      differentCTX.fillRect(particles[i].x, particles[i].y, 2, 2);
    }
  }


  anotherDraw(anotherCanvas, img, anotherCTX, particles) {
    anotherCanvas.height = img.height;
    anotherCanvas.width = img.width;
    anotherCTX.drawImage(img, 0, 0, img.width, img.height);
    let data = anotherCTX.getImageData(0, 0, img.width, img.height);
    // anotherCTX.clearRect(0, 0, canvas.width, canvas.height);
    let particle = {};
    particles.length = 0;

    for (let y = 0; y < data.height; y++) {
      for (let x = 0; x < data.width; x++) {
        let p = (x + y * data.width) * 4;
        particle = {
          x: x,
          y: y,
          red: 255 - data.data[p],
          green: 255 - data.data[p + 1],
          blue: 255 - data.data[p + 2],
          alpha: data.data[p + 3],
        }
        particles.push(particle);
      }
    }
    // anotherCTX.clearRect(0, 0, canvas.width, canvas.height);
    // console.log(particles.length);
    for (let i = 0; i < particles.length; i++) {
      // anotherCTX.fillStyle = particles[i].color;
      anotherCTX.fillStyle = `rgba(
        ${particles[i].red}, 
        ${particles[i].green},
        ${particles[i].blue},
        ${particles[i].alpha})`;
      anotherCTX.fillRect(particles[i].x, particles[i].y, 2, 2);
    }
  }


  againAnotherDraw(againAnotherCanvas, img, againAnotherCTX, particles) {
    againAnotherCanvas.height = img.height;
    againAnotherCanvas.width = img.width;
    againAnotherCTX.drawImage(img, 0, 0, img.width, img.height);
    let data = againAnotherCTX.getImageData(0, 0, img.width, img.height);
    // againAnotherCTX.clearRect(0, 0, canvas.width, canvas.height);
    let particle = {};
    particles.length = 0;
    let factor = 1;

    for (let y = 0; y < data.height - 1; y++) {
      for (let x = 1; x < data.width - 1; x++) {
        let p = (x + y * data.width) * 4;
        let p1 = ((x + 1) + (y) * data.width) * 4;
        let p2 = ((x + 1) + (y + 1) * data.width) * 4;
        let p3 = ((x) + (y + 1) * data.width) * 4;
        let p4 = ((x + 1) + (y + 1) * data.width) * 4;
        let red = data.data[p];
        let green = data.data[p + 1];
        let blue = data.data[p + 2];
        let quantizeRed = Math.round(factor * data.data[p] / 255) * (255 / factor);
        let quantizeGreen = Math.round(factor * data.data[p + 1] / 255) * (255 / factor);
        let quantizeBlue = Math.round(factor * data.data[p + 2] / 255) * (255 / factor);

        data.data[p] = quantizeRed;
        data.data[p + 1] = quantizeGreen;
        data.data[p + 2] = quantizeBlue;
        let errorRed = red - quantizeRed;
        let errorGreen = green - quantizeGreen;
        let errorBlue = blue - quantizeBlue;

        data.data[p1] = data.data[p1] + errorRed * 7 / 16;
        data.data[p1 + 1] = data.data[p1 + 1] + errorGreen * 7 / 16;
        data.data[p1 + 2] = data.data[p1 + 2] + errorBlue * 7 / 16;

        data.data[p2] = data.data[p2] + errorRed * 3 / 16;
        data.data[p2 + 1] = data.data[p2 + 1] + errorGreen * 3 / 16;
        data.data[p2 + 2] = data.data[p2 + 2] + errorBlue * 3 / 16;

        data.data[p3] = data.data[p3] + errorRed * 5 / 16;
        data.data[p3 + 1] = data.data[p3 + 1] + errorGreen * 5 / 16;
        data.data[p3 + 2] = data.data[p3 + 2] + errorBlue * 5 / 16;

        data.data[p4] = data.data[p4] + errorRed * 1 / 16;
        data.data[p4 + 1] = data.data[p4 + 1] + errorGreen * 1 / 16;
        data.data[p4 + 2] = data.data[p4 + 2] + errorBlue * 1 / 16;

        particle = {
          x: x,
          y: y,
          red: data.data[p],
          green: data.data[p + 1],
          blue: data.data[p + 2],
          alpha: data.data[p + 3],
        }
        particles.push(particle);
      }
    }
    // againAnotherCTX.clearRect(0, 0, canvas.width, canvas.height);
    // console.log(particles.length);
    for (let i = 1; i < particles.length; i++) {
      // againAnotherCTX.fillStyle = particles[i].color;
      let red = particles[i].red;
      let green = particles[i].green;
      let blue = particles[i].blue;
      againAnotherCTX.fillStyle = `rgba(
        ${red}, 
        ${green},
        ${blue},
        ${particles[i].alpha})`;
      againAnotherCTX.fillRect(particles[i].x, particles[i].y, 2, 2);
    }
  }

  setImage(event) {
    //sets the image src and displays the image if it was hidden from the onSubmitting() function
    //also checks if there was an error with the input and will either display an error or show the default image
    document.getElementById("the-image-to-manipulate").onerror = this.handleError;
    document.getElementById("the-image-to-manipulate").onload = this.handleImageLoad;
    document.getElementById("inputText").textContent = event.target.value;
    if (event.target.value === '' && this.state.error) {
      this.setState({
        error: false,
      });
      document.getElementById("the-image-to-manipulate").src = this.imageSrc;
    } else if (event.target.value === '') {
      document.getElementById("the-image-to-manipulate").src = this.imageSrc;
      // document.getElementById("the-image-to-manipulate").style.display = "inline";
    } else {
      document.getElementById("the-image-to-manipulate").src = event.target.value;
      document.getElementById("the-image-to-manipulate").style.display = "inline";
    }
  }

  checkForEnterKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault(); //needed to stop the page from refreshing since were not actually submitting anything
                              //and were just listening for the event.
      event.target.blur();    //removes the cursor from the textbox, closes mobile keyboards after a search
      this.onSubmitting();
    }
  }

  checkBoxChange() {
    if (document.getElementById("the-image-to-manipulate").style.height === "auto") {
      document.getElementById("the-image-to-manipulate").style.height = "50vw";
      document.getElementById("imageSizeCheckboxText").textContent = "Retain Original Size Of Image."
    } else {
      document.getElementById("the-image-to-manipulate").style.height = "auto";
      document.getElementById("imageSizeCheckboxText").textContent = "Retain Original Size Of Image. (Large Images May Cause The Process To Take Longer)";
    }
  }

  render() {

    return (
      <section className="section" id="image-manipulation-section">
        <div className="container">

          <form>
            <label className="main-text"><h3>Enter An Image Link In The Field Below</h3></label>
            <label className="main-text"><h6>Find an image online -&gt; right click or long press the image -&gt; copy image link -&gt; paste into the Image Manipulation form field -&gt; push the button -&gt; enjoy</h6></label>
            <input
              id="inputText"
              className="form-control"
              onChange={(event) => this.setImage(event)}
              onKeyPress={(event) => this.checkForEnterKeyPress(event)}
              placeholder="add image link here"
              type="text"
            />
          </form>

          {/* Retain original size of image checkbox */}
          <div className="form-check" style={{ "paddingTop": "1rem" }}>
            <label className="container">
              <input type="checkbox" defaultChecked="" onChange={() => this.checkBoxChange()} />
              <span id="imageSizeCheckboxText" style={{ "paddingLeft": "0.3rem" }}>Retain Original Size Of Image.</span>
            </label>
          </div>

          {/* Manipulate Image Button */}
          <div style={{ "paddingTop": "1rem", "paddingBottom": "2rem" }}>
            <button className="btn im-page-buttons" onClick={() => this.onSubmitting()}>Manipulate Image</button>
          </div>

          <div id="loadingDiv" className="section" style={{ display: "none" }}>
            <h3>Loading...</h3>
          </div>

          {this.state.error ?
            <div className="section">
              <div id="linkError">
                <h3>Error: Incorrect information loaded</h3>
              </div>
            </div>
            : ""
          }

          {/* THIS IS HOW IT WORKS WITH A LOCAL IMAGE */}
          {/* <img id="the-image-to-manipulate" src={require("../images/burger.PNG")} alt="hamburger icon" /> */}
          {/* <img id="the-image-to-manipulate" src={require("../images/self.jpg")} alt="self" /> */}
          {/* THIS IS HOW IT WORKS WITH A LINK */}
          <div className="container">
            <img
              id="the-image-to-manipulate"
              crossOrigin="anonymous"
              src={this.imageSrc}
              alt="what will be processed"
            />
          </div>

          <div className="container">
            <canvas id="myCanvas"></canvas>
            <canvas id="myOtherCanvas"></canvas>
            <canvas id="myOtherOtherCanvas"></canvas>
            <canvas id="myOtherOtherOtherCanvas"></canvas>
          </div>

        </div>
      </section>
    );
  }
}

export default ImageManipulation;
