import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';



class Error extends Component {

  render() {
    return (
      <div className="section" id="error-section">
        <h4 id="error-heading">
          Error: 404  Oh No, This Page Doesn't Exist..
        </h4>
        <div className="container">
          <NavLink
            to="/home"
            id="error-button"
            className="btn btn-info">
            Home
          </NavLink>
        </div>
      </div>
    );
  }
}

export default Error