import React, { Component } from 'react';
import PostData from './videoList.json';
import Videos from './videos';

class MusicPage extends Component {
  constructor() {
    super();

    this.state = {
      page: 0,
      perPage: 6,
      searchTerm: '',
      searchedVideos: {
        videos: [{}]
      },
      pagedVideos: {
        videos: [{}]
      },
    };
  }

  componentDidMount() {
    this.moveToTop();
    this.onMount();
    console.log("There are 'key errors' but no matter how many times i add keys they still remain, also, this is the only page that complains, so somthing is amiss.  If you know whats going on i would gladly listen, but after hours and hours and hours of searching i cant find any solutions.  I can rerender the dom after adding keys and changing those keys, but the errors still remain. The keys must be working in those cases, even if its not the result i want, yet still the key errors exist. What a joke")
  }

  moveToTop() {
    window.scrollTo(0, 0);
  }

  onMount() {
    const { page, perPage } = this.state;
    const start = perPage * page;
    this.setState({
      pagedVideos: { videos: PostData.videos.slice(start, start + perPage) },
      searchedVideos: PostData,
      page: page + 1
    })
  }

  nextSet() {
    this.moveToTop();
    const { perPage, page, searchedVideos } = this.state;
    const start = perPage * page;
    this.setState({
      pagedVideos: { videos: searchedVideos.videos.slice(start, start + perPage) },
      page: page + 1
    });
  }

  prevSet() {
    this.moveToTop();
    const { perPage, page, searchedVideos } = this.state;
    const offset = 2; //go back two since page is changed after we access the data
    const start = perPage * (page - offset);
    this.setState({
      pagedVideos: { videos: searchedVideos.videos.slice(start, start + perPage) },
      page: page - 1
    });
  }

  searchTermChanged(event) {
    let pData = PostData.videos.filter((el) => el.title.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1);
    if (pData.length <= 0) {
      pData = {};
    }
    const sVideos = this.state.searchedVideos.videos.filter((el) => el.title.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1);
    //this checks length, first and last entry if they match, then the list updates
    if (!(pData.length === sVideos.length && pData[0].title === sVideos[0].title && pData[pData.length - 1].title === sVideos[sVideos.length - 1].title)) {
      this.setState({
        searchTerm: event.target.value,
        searchedVideos: event.target.value === "" ? PostData : { videos: PostData.videos.filter((el) => el.title.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) },
        page: 0,
        pagedVideos: { videos: [] },
      }, this.nextSet);
    }
  }

  getTotalPages() {
    const { searchedVideos, searchTerm, perPage } = this.state;
    if (searchedVideos.videos.length > 0 && searchTerm) {
      return Math.ceil(searchedVideos.videos.length / perPage);
    } else if (searchedVideos.videos.length === 0 && searchTerm) {
      return 0;
    } else {
      return Math.ceil(PostData.videos.length / perPage)
    }
  }

  loadFirstPage() {
    this.moveToTop();
    const { perPage, searchedVideos } = this.state;
    this.setState({
      pagedVideos: { videos: searchedVideos.videos.slice(0, perPage) },
      page: 1,
    })
  }

  loadLastPage() {
    this.moveToTop();
    const { perPage, searchedVideos } = this.state
    const lastPage = this.getTotalPages();
    const offset = 1; //have to offset due to zero index, state sets page after its used
    const start = perPage * (lastPage - offset);
    this.setState({
      pagedVideos: { videos: searchedVideos.videos.slice(start, start + perPage) },
      page: lastPage,
    })
  }


  render() {
    const { pagedVideos, page } = this.state;
    const totalPages = this.getTotalPages();
    return (
      <section className="section" id="music-section">
        <div className="container">

          <h4 className="main-heading">
            My List Of Music
              </h4>
          <h5 className="main-heading text-left">
            I hand selected a list of music.
            I know this music is not for everyone, but hopefully you'll find something that grabs your interest.
              </h5>

          <form> 
            <label><h3>Search The List</h3></label>
            <input
              className="form-control"
              type="text"
              style={{ "marginBottom": "2rem" }}
              onChange={(event) => this.searchTermChanged(event)}
              onKeyDown={event => { if (event.key === "Enter") { event.preventDefault(); event.target.blur() } }}
            />
          </form>

          <div id="video-container">

            {<Videos {...pagedVideos} />}

          </div>

          {/* LOAD BUTTONS IF THERE ARE MORE VIDEOS */}
          {page <= 1 ? '' : <button
            className="btn music-list-buttons"
            onClick={() => this.prevSet()}>Previous Set </button>}
          {page < totalPages ? <button
            className="btn music-list-buttons"
            onClick={() => this.nextSet()}>Next Set </button> : ''}
          {totalPages ? <h5>Current Page: {page}</h5> : ''}
          <h6>Total Pages: {totalPages}</h6>

          {page <= 1 ? '' : <button
            className="btn music-list-buttons"
            onClick={() => this.loadFirstPage()}>First Set </button>}
          {page < totalPages ? <button
            className="btn music-list-buttons"
            onClick={() => this.loadLastPage()}>Last Set </button> : ''}
        </div>
      </section>
    );
  }
}

export default MusicPage;
