import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import FakeNavBar from './components/fakeNavBar';
import HomePage from './components/homePage';
import AboutPage from './components/aboutPage';
import PortfolioPage from './components/portfolioPage';
import MusicPage from './components/musicPage';
import Footer from './components/footer';
import MTGAPI from './components/mtgapi';
import ImageManipulation from './components/imageManipulation';
import Error from './components/error';


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div key="br-div" id="main">
          <FakeNavBar/>
          <Switch key="switch">
            <Route exact path="/"    key="slash"     component={HomePage}/>
            <Route path="/home"      key="home"      component={HomePage}/>
            <Route path="/about"     key="about"     component={AboutPage}/>
            <Route path="/portfolio" key="portfolio" component={PortfolioPage}/>
            <Route path="/music"     key="music"     component={MusicPage}/>
            <Route path="/mtg"       key="mtgapi"    component={MTGAPI}/>
            <Route path="/images"    key="images"    component={ImageManipulation}/>
            <Route key="error" component={Error} />
          </Switch>
          <Footer/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;