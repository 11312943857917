import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class PortfolioPage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <section className="section" id="portfolio-section">
        <h1 className="main-heading" style={{color: "black"}}>
          Some Of My Projects:
              </h1>
        <div className="container">


          {/* ADDONS */}
          <table className="table table-hover text-left">
            <thead className="text-center custom-portfolio-table-header-bg-color">
              <tr>
                <th><h5>Elder Scrolls Online AddOns: Currenly over 45,000 total downloads</h5></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="portfolio-table-data">My Main AddOn Author Page</td>
                <td>
                  <a href="https://www.esoui.com/downloads/author-12804.html" target="_blank" rel="noopener noreferrer">
                    Author Page
                      </a>
                </td>
              </tr>
              <tr>
                <td className="portfolio-table-data">Calculator
                      <br></br>
                  Uses the in-game chatbox
                      <br></br>
                  And has a UI written in xml
                    </td>
                <td>
                  <a href="https://www.esoui.com/downloads/info697-Calculator.html" target="_blank" rel="noopener noreferrer">
                    Calculator On Esoui.com
                      </a>
                  <br></br>
                  <a href="https://github.com/zeroIndex0/Calculator" target="_blank" rel="noopener noreferrer">
                    Calculator On My Github
                      </a>
                </td>
              </tr>
              <tr>
                <td>Auto Weapon Charging AddOn</td>
                <td>
                  <a href="https://www.esoui.com/downloads/info2063-WeaponCharger.html" target="_blank" rel="noopener noreferrer">
                    Weapon Charger On Esoui.com
                      </a>
                  <br></br>
                  <a href="https://github.com/zeroIndex0/WeaponCharger" target="_blank" rel="noopener noreferrer">
                    Weapon Charger On My Github
                      </a>
                </td>
              </tr>
            </tbody>
          </table>



          {/* RANDOM ONE OFFS */}
          {/* <table className="table table-hover text-left">
            <thead className="text-center custom-portfolio-table-header-bg-color">
              <tr>
                <th><h5>Random Stuff</h5></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="portfolio-table-data">Random code, a mix of random stuff. Some intersting, some maybe not so interesting.
                    </td>
                <td>
                  <a href="https://github.com/zeroIndex0/randomCode" target="_blank" rel="noopener noreferrer">Random Bits Of Code - Github</a>
                </td>
              </tr>
            </tbody>
          </table> */}


          {/* Music Page */}
          <table className="table table-hover text-left">
            <thead className="text-center custom-portfolio-table-header-bg-color">
              <tr>
                <th><h5>Music Page</h5></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="portfolio-table-data">
                  I implemented a search feature that will search titles and artists in a json list with each keypress.
                  Therefore, the list will update live while typing.
                    <br></br>
                  The list is long, so I limit each page to show five videos, due to the youtube imbed hogging a lot of power.
                    </td>
                <td>
                  {/* <a href="https://thekirbs.com/music">The Kirbs Music</a> */}
                  <NavLink to="/music">The Kirbs Music</NavLink>
                </td>
              </tr>
            </tbody>
          </table>


          {/* Magic The Gathering Page */}
          <table className="table table-hover text-left">
            <thead className="text-center custom-portfolio-table-header-bg-color">
              <tr>
                <th><h5>Magic The Gathering</h5></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="portfolio-table-data">
                  The MTG page uses an API for Magic The Gathering to display card names, images, and data based on a users input.
                    <br></br>
                  I also include a link to amazon incase you want to price check the card.
                    <br></br>
                </td>
                <td>
                  {/* <a href="https://thekirbs.com/music">The Kirbs Music</a> */}
                  <NavLink to="/mtg">Magic The Gathering Page</NavLink>
                </td>
              </tr>
            </tbody>
          </table>


          {/* Image Manipulation Page */}
          <table className="table table-hover text-left">
            <thead className="text-center custom-portfolio-table-header-bg-color">
              <tr>
                <th><h5>Image Manipulation / Processing</h5></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="portfolio-table-data">
                  <strong>This is my personal favorite page.</strong>
                  <br></br>
                  The Image Manipulation page allows you to input an image link into the field. Then, after pushing a button,
                  perform four image processing algorithms on the linked image.  You have an option to keep the original size of the image
                  or you can keep it as the default smaller version.
                    <br></br>
                  <ul>
                    The four image processing algorithms are (in order):
                    <li>Basic copy paste of all the pixels.</li>
                    <li>Thresholding.  Given the current pixel's value, I substitue the pixels colors.</li>
                    <li>Turning the image into its negative color version.</li>
                    <li>Floyd-Steinberg dithering.  This algorithm can be found on &nbsp;
                      <a href="https://en.wikipedia.org/wiki/Floyd%E2%80%93Steinberg_dithering" target="_blank" rel="noopener noreferrer">wikipedia</a>.
                      </li>
                  </ul>
                  <br></br>
                  Find an image online -&gt; right click or long press the image -&gt; copy image link -&gt; paste into the Image Manipulation form field -&gt; push the button -&gt; enjoy
                  </td>
                <td>
                  {/* <a href="https://thekirbs.com/music">The Kirbs Music</a> */}
                  <NavLink to="/images">Image Manipulation Page</NavLink>
                </td>
              </tr>
            </tbody>
          </table>


                   {/* BITMAP PHOTO CREATOR */}
                   <table className="table table-hover text-left">
            <thead className="text-center custom-portfolio-table-header-bg-color">
              <tr>
                <th><h5>Bitmap Photo Creator</h5></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="portfolio-table-data">
                  Formatting is how you let the photo viewer know that your data is actually an image.  It was a very interesting study.
                    </td>
                <td>
                  <a href="https://github.com/zeroIndex0/BitmapPhotoCreator" target="_blank" rel="noopener noreferrer">Bitmap Photo Creator</a>
                </td>
              </tr>
            </tbody>
          </table>


          {/* CRUD PERSONAL WEBSITE */}
          <table className="table table-hover text-left">
            <thead className="text-center custom-portfolio-table-header-bg-color">
              <tr>
                <th><h5>CRUD Website - LAMP stack</h5></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="portfolio-table-data">A webpage written in PHP using a MySQL database that takes input from a form and either creates, updates, or deletes the data in the database.
                    </td>
                <td>
                  <p> I took the website down because I was tired of paying to read bot spam about dropshipping and crypto </p>
                  <br></br>
                  <a href="https://github.com/zeroIndex0/hello_database_world" target="_blank" rel="noopener noreferrer">The Source Code - Github</a>
                </td>
              </tr>
            </tbody>
          </table>


           {/* DATA STRUCTURES */}
           {/* <table className="table table-hover text-left">
            <thead className="text-center custom-portfolio-table-header-bg-color">
              <tr>
                <th><h5>Data Structures:   If you're into that sort of thing (written in C++)</h5></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="portfolio-table-data">Binary Tree</td>
                <td>
                  <a href="https://github.com/zeroIndex0/dataStructures/blob/master/binaryTree.h"
                    target="_blank"
                    rel="noopener noreferrer">
                    Binary Tree Header File
                      </a>
                  <br></br>
                  <a href="https://github.com/zeroIndex0/dataStructures/blob/master/binaryTreeTest.cpp"
                    target="_blank"
                    rel="noopener noreferrer">
                    Binary Tree CPP File
                      </a>
                </td>
              </tr>
              <tr>
                <td className="portfolio-table-data">Linked List</td>
                <td>
                  <a href="https://github.com/zeroIndex0/dataStructures/blob/master/linkedList.h"
                    target="_blank"
                    rel="noopener noreferrer">
                    Linked List Header File
                      </a>
                  <br></br>
                  <a href="https://github.com/zeroIndex0/dataStructures/blob/master/linkedListTester.cpp"
                    target="_blank"
                    rel="noopener noreferrer">
                    Linked List CPP File
                      </a>
                </td>
              </tr>
              <tr>
                <td className="portfolio-table-data">Stack</td>
                <td>
                  <a href="https://github.com/zeroIndex0/dataStructures/blob/master/stack.h"
                    target="_blank"
                    rel="noopener noreferrer">
                    Stack Header File
                      </a>
                  <br></br>
                  <a href="https://github.com/zeroIndex0/dataStructures/blob/master/stackTest.cpp"
                    target="_blank"
                    rel="noopener noreferrer">
                    Stack CPP File
                      </a>
                </td>
              </tr>
              <tr>
                <td className="portfolio-table-data">Hash Table
                    </td>
                <td>
                  <a href="https://github.com/zeroIndex0/dataStructures/blob/master/hashTable.h"
                    target="_blank"
                    rel="noopener noreferrer">
                    Hash Table Header File
                      </a>
                  <br></br>
                  <a href="https://github.com/zeroIndex0/dataStructures/blob/master/hashTableTester.cpp"
                    target="_blank"
                    rel="noopener noreferrer">
                    Hash Table CPP File
                      </a>
                </td>
              </tr>
            </tbody>
          </table>
          <hr></hr> */}


          <div style={{ textAlign: "left" }}>
            <br></br>
            <br></br>
            <br></br>
            {/* <h5>
              There are also links at the bottom of the page that will bring you to
              my github.  You can also see my codwars profile, check out my linkedin and/or send me an email.
                </h5> */}
          </div>
        </div>
      </section>
    );
  }

}

export default PortfolioPage;