import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';


class FakeNavBar extends Component {

  // expandContract() {
  //   const navbar = document.getElementById("mynav");
  //   if (navbar.className === "nav-row") {
  //     navbar.className += " nav-row-responsive";
  //   } else {
  //     navbar.className = "nav-row";
  //   }
  // }

  // closeMenu() {
  //   const navbar = document.getElementById("mynav");
  //   const navButton = document.querySelector(".nav-icon");
  //   if (navbar && navButton) {
  //     navbar.className = "nav-row";
  //   }
  // }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light sticky-top" style={{ "backgroundColor": '#e3c565' }}
        data-toggle="collapse" data-target=".navbar-collapse.show">
        <NavLink className="navbar-brand" to="/home">
          <img src={require("../images/logoHomeRetouch.png")} alt="logo" />
          {/* <img src={require("../images/logoHomeRetouch2.png")} /> */}
        </NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" to="/about">About </NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" to="/portfolio">Portfolio </NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" to="/music">Music </NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" to="/mtg">MTG </NavLink>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <NavLink className="nav-link" to="/images">Image Manipulation </NavLink>
            </li>
          </ul>
        </div>
      </nav>

      // <section className="nav-row" id="mynav">
      //   <div className="two columns no-icon">
      // <NavLink to="/home" className="button nav-button" activeClassName="button-primary home-button-selected">
      //   <img id="home-icon" src={require("../images/logoHome.png")} alt="home button icon" />
      // </NavLink>
      //   </div>
      //   <div className="two columns no-icon">
      //     <NavLink to="/about" className="button nav-button" activeClassName="button-primary">About</NavLink>
      //   </div>
      //   <div className="two columns no-icon">
      //     <NavLink to="/portfolio" className="button nav-button" activeClassName="button-primary">Portfolio</NavLink>
      //   </div>
      //   <div className="two columns no-icon">
      //     <NavLink to="/music" className="button nav-button" activeClassName="button-primary">Music</NavLink>
      //   </div>
      //   <div className="two columns no-icon">
      //     <NavLink to="/mtg" className="button nav-button" activeClassName="button-primary">MTG</NavLink>
      //   </div>
      //   <div className="two columns no-icon">
      //     <NavLink to="/images" className="button nav-button" activeClassName="button-primary">Image Manipulation</NavLink>
      //   </div>
      //   <div>
      //     <button className="nav-icon button" onClick={() => this.expandContract()}>
      //       <img id="hamburger-icon" src={require("../images/burger.PNG")} alt="hamburger icon" />
      //     </button>
      //   </div>
      //   {this.closeMenu()}
      // </section>
    );
  }
}

export default FakeNavBar; 